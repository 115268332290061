<template>
  <div class="login">
<!--    <canvas class="canvass"></canvas>-->
<!--    <div class="title">-->
<!--    </div>-->
    <div class="login-card">
      <div class="login-header">
        <div class="login-logo"></div>
        <div class="login-title">
          <div class="welcome">欢迎登录</div>
          <div class="system-name">"卫全物管"全域监管培训平台
          </div>
<!--          <div class="system-desc">物联网预警 / 电梯维保 / AI识别 / 应急救援一站式解决方案</div>-->
        </div>
      </div>
      <a-form-model ref="loginForm" :model="formData" :rules="formRules" class="login-form normal-form">
        <a-form-model-item prop="username">
          <a-input v-model.trim="formData.username" placeholder="账户" size="large">
            <a-icon slot="prefix" type="user" style="color: #1890ff" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input v-model.trim="formData.password" type="password" placeholder="密码" size="large">
            <a-icon slot="prefix" type="lock" style="color: #1890ff" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <div style="display: flex;">
            <a-input v-model.trim="formData.code" @keyup.enter.native="checkFormData" placeholder="验证码" size="large" style="flex: 1;">
              <a-icon slot="prefix" type="safety-certificate" style="color: #1890ff" />
            </a-input>
            <vertify-code ref="vertifyCode" @get-code="getVertifyCode" style="width: 123px;flex-shrink: 0;margin-left: 10px;"></vertify-code>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-checkbox :checked="formData.autologin" @change="formData.autologin=!formData.autologin" style="color: white">自动登录</a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" size="large" @click="checkFormData" style="width: 100%;">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="compony-info"> Copyright © 2020 南京优湖科技有限公司 </div>
<!--    <div class="compony-info"><a href="https://beian.miit.gov.cn" target="_blank" style="color: white">湘ICP备2021017264-1</a>| Copyright © 2020 南京优湖科技有限公司 | 网址：www.esp-ulake.com</div>-->
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import { setCache, getCache } from '@/utils'
import {getUserByUserid, login} from 'A/login'
import VertifyCode from 'C/VertifyCode'
import {getDictByDicType, getDictionaryList} from "A/xtpz";
import {getTreeOfUserDepHasUser} from "A/jcgn";
import {decryptData, encryptData} from "U/crypto-js";
export default {
  name: 'login',
  components: {
    VertifyCode
  },
  data() {
    return {
      areaid:'',
      // userid:'',
      phonename:'',
      phonepassword:'',
      phonextnum:'',
      allowremote:'',
      isverification:true,
      verification:'',
      verificationenable:'',
      deleteenable:'',
      userinforesetable:'',
      deviceparamable:'',
      receiveVerification:'',
      formData: {
        username: '',
        password: '',
        code: '',
        autologin: false
      },
      formRules: {
        username: [{required: true, message: '请输入账户'}],
        password: [{required: true, message: '请输入密码'}],
        code: [
          {required: true, message: '请输入验证码'},
          {
            validator: (rule, value, callback) => {
              if(value.toUpperCase() === this.vertifyCode.toUpperCase()) {
                callback();
              }else {
                callback('验证码错误')
              }
            }
          }
        ],
      },
      vertifyCode: '',
    }
  },
  created() {
    this.checkAutologin();
  },
  methods: {
    checkAutologin() {
      setCache("isMobile",0,true);
      let logininfo = getCache('logininfo', true);
      if(logininfo&&logininfo.password) {
        this.formData.autologin = true;
        this.formData.username = logininfo.username;
        this.formData.password = logininfo.password;
        this.login();
      }
    },
    getVertifyCode(vertifyCode) {
      this.vertifyCode = vertifyCode;
    },
    checkFormData() {
      this.$refs.loginForm.validate(valid => {
        if(valid) {
          // 自动登录
          if(this.formData.autologin) {
            setCache('logininfo', {
              username: this.formData.username,
              password: this.formData.password,
            }, true);
          }
          this.login();
        }else {
          this.$refs.vertifyCode.refreshCode();
          return false;
        }
      })
    },
    WebSocketTest() {
      let that = this
      if ("WebSocket" in window) {
        let ws = new WebSocket("ws://127.0.0.1:39999/ws");
        ws.onopen = function () {
          ws.send("GetTheCPUID");
        };
        ws.onmessage = function (evt) {
          let msg = evt.data;
          that.receiveVerification=msg;
          // console.log("返回验证信息: " , that.receiveVerification)
          // alert("返回验证信息: " + that.receiveVerification);
          if(that.verification!=null&&that.verification!=""&&that.verification==that.receiveVerification){
            console.log("身份验证成功")
            that.isverification=false
            // if(that.formData.password==that.useraccount.toString()+"@1234"){
            //   that.$router.push({name: 'userCenter'})
            //   that.$message.info("当前密码为初始密码，请修改密码后再进行使用")
            // }else {
              for (let i = 0; i < that.menuLists.length; i++) {
                if (that.menuLists[i].menuid == 92 && that.menuLists[i].menucode == "02") {
                  if (that.menuLists[i].children[0].menucode == "0201") {
                    that.$router.push({
                      name: 'superviseBigData',
                      params: true,
                    })
                    return;
                  } else {
                    that.$router.push({
                      name: 'projectBigData',
                      params: true,
                    })
                    return;
                  }
                } else {
                  that.$router.push({
                    name: 'home',
                  })
                }
              }
              that.$router.push({
                name: 'home',
              })
            // }
          }else{
            console.log("身份验证失败")
            that.isverification=true
          }
          ws.close();
        };
        ws.onclose = function () {
          console.log("身份验证连接关闭")
          if(that.isverification){
            that.$warning({
              title: '提示',
              content: '此电脑非指定的管理计算机，请到指定的管理计算机上进行操作，谢谢！',
            })
          }
        };
      } else {
        console.log("您的电脑未安装验证插件，请安装后登录重试")
        // alert("您的电脑未安装验证插件，请安装后登录重试");
      }
    },
    login() {
      this.showLoading();
      let params = {
        // useraccount: this.formData.username,
        // password: this.formData.password,
        // apptype: '1', // 0:app 1:pc
        useraccount: encryptData(this.formData.username),
        password: encryptData(this.formData.password),
        apptype: '1', // 0:app 1:pc
      }
      login(params).then(res => {
        if(res && res.returncode == '0') {
          this.userid = res.userid.toString();
          this.usertype = res.usertype;
          this.usernames = res.username;
          this.userdepid=res.userdepid;
          this.menuLists=res.menu;
          this.rolename=res.rolename;
          if(this.formData.autologin){
            setCache('logininfo', {
              username: this.formData.username,
              password: this.formData.password,
              userdepid:this.userdepid,
              usersid: this.userid,
              usertype:this.usertype,
              usernames:this.usernames,
              rolename:this.rolename,
            }, true);
          }else{
            setCache('logininfo', {
              username: this.formData.username,
              userdepid:this.userdepid,
              usersid: this.userid,
              usertype:this.usertype,
              usernames:this.usernames,
              rolename:this.rolename,
            }, true);
          }
          this.$store.commit('setToken', 'platform '+res.token);
          this.$store.commit('setUserInfo', res);
          this.$store.commit('setMenuList', res.menu);

          getDictionaryList({}).then(res=>{
            if(res&&res.returncode=='0'){
              for(let i=0;i<res.equipmentTypes.length;i++){
                res.equipmentTypes[i].dickey=Number(res.equipmentTypes[i].dickey)
              }
              setCache('dictionary', {
                bigInfluences: res.bigInfluences,
                alarmTypes: res.alarmTypes,
                alarmTypesMap: res.alarmTypesMap,
                alarmChildTypes: res.alarmChildTypes,
                eventInfos: res.eventInfos,
                systemNames: res.systemNames,
                systemNamesMap: res.systemNamesMap,
                feedbackList: res.feedbackList,
                abnormalTypes: res.abnormalTypes,
                abnormalTypesMap: res.abnormalTypesMap,
                equipmentTypes: res.equipmentTypes,
                manageTypes: res.manageTypes,
                recordTypes: res.recordTypes,
                positionTypes: res.positionTypes,
                causeClassifyList: res.causeClassifyList,
                sceneTypes: res.sceneTypes,
                maintainNameList: res.maintainNameList,
                deviceCodeList: res.deviceCodeList,
                equipmentParamsList: res.equipmentParamsList,
                equipmentCategoryList: res.equipmentCategoryList,
                equipmentCategoryMap: res.equipmentCategoryMap,
                equipmentChildCategoryList: res.equipmentChildCategoryList,
                equipmentChildCategoryMap: res.equipmentChildCategoryMap,
                machineRoomTypeList: res.machineRoomTypeList,
                equipmentStatusList: res.equipmentStatusList,
                equipmentSceneList: res.equipmentSceneList,
                equipmentModeList: res.equipmentModeList,
                maintenancePeriodList: res.maintenancePeriodList,
                waterSystemEventList: res.waterSystemEventList,
                electricSystemEventList: res.electricSystemEventList,
                equipmentMaintenanceItemList: res.equipmentMaintenanceItemList,
                GPIOList: res.GPIOList,
                MeasureUnitList: res.MeasureUnitList,
                MeasureTypeList: res.MeasureTypeList,
                StandardValueMap: res.StandardValueMap,
              }, true);
            }
          })
          getTreeOfUserDepHasUser({}).then(res=>{
            if(res&&res.returncode=='0'){
              setCache('userDepLists', {
                userDepList: res.item,
              }, true);
            }
          })
          getUserByUserid({userid:this.userid}).then(res=> {
            if (res && res.returncode == '0') {
              this.areaid=res.item.areaid
              this.phonename=res.item.phonename
              this.phonepassword=res.item.phonepassword
              this.phonextnum=res.item.phonextnum
              this.remotenable=res.item.remotenable
              this.allowremote=res.item.allowremote
              this.verification=res.item.verification
              this.verificationenable=res.item.verificationenable
              this.deleteenable=res.item.deleteenable
              this.userinforesetable=res.item.userinforesetable
              this.deviceparamable=res.item.deviceparamable
              this.playtype = res.item.playtype
              this.userdepid = res.item.userdepid
              this.managetype=res.item.managetype
              this.userdepinfoable=res.item.userdepinfoable
              this.incidentvisible=res.item.incidentvisible
              this.useraccount=res.item.useraccount
              if(this.formData.autologin){
                setCache('logininfo', {
                  username: this.formData.username,
                  password: this.formData.password,
                  usersid: this.userid,
                  playtype: this.playtype,
                  userdepid:this.userdepid,
                  usertype: this.usertype,
                  usernames: this.usernames,
                  areacode: this.areaid,
                  phonename:this.phonename,
                  phonepassword:this.phonepassword,
                  phonextnum:this.phonextnum,
                  remotenable:this.remotenable==1,
                  allowremote:this.allowremote,
                  deleteenable:this.deleteenable,
                  deviceparamable:this.deviceparamable,
                  userinforesetable:this.userinforesetable,
                  managetype:this.managetype,
                  userdepinfoable:this.userdepinfoable,
                  incidentvisible:this.incidentvisible,
                  rolename:this.rolename,
                }, true);
                this.$store.state.shiftFlag=(this.rolename=="项目调度员"||this.rolename==="项目工程值班员")?true:false;
              }else{
                setCache('logininfo', {
                  username: this.formData.username,
                  usersid: this.userid,
                  playtype: this.playtype,
                  userdepid:this.userdepid,
                  usertype: this.usertype,
                  usernames: this.usernames,
                  areacode: this.areaid,
                  phonename:this.phonename,
                  phonepassword:this.phonepassword,
                  phonextnum:this.phonextnum,
                  remotenable:this.remotenable==1,
                  allowremote:this.allowremote,
                  deleteenable:this.deleteenable,
                  deviceparamable:this.deviceparamable,
                  userinforesetable:this.userinforesetable,
                  managetype:this.managetype,
                  userdepinfoable:this.userdepinfoable,
                  incidentvisible:this.incidentvisible,
                  rolename:this.rolename,
                }, true);
                this.$store.state.shiftFlag=(this.rolename==="项目调度员"||this.rolename==="项目工程值班员")?true:false;
              }
              if(this.rolename==="项目调度员"&&this.verificationenable!==1){
                this.WebSocketTest()
              }else if(this.rolename==="系统维护管理员"){
                this.$router.push({
                  name: 'netReport',
                  params: true,
                })
              }else {
                // if(this.formData.password==this.useraccount.toString()+"@1234"){
                //   this.$router.push({name: 'userCenter'})
                //   this.$message.info("当前密码为初始密码，请修改密码后再进行使用")
                // }else{
                for (let i = 0; i < this.menuLists.length; i++) {
                  if (this.menuLists[i].menuid == 92 && this.menuLists[i].menucode == "02") {
                    if (this.menuLists[i].children[0].menucode == "0201") {
                      this.$router.push({
                        name: 'superviseBigData',
                        params: true,
                      })
                      return;
                    } else {
                      this.$router.push({
                        name: 'projectBigData',
                        params: true,
                      })
                      return;
                    }
                  } else {
                    this.$router.push({
                      name: 'home',
                    })
                  }
                }
                this.$router.push({
                  name: 'home',
                })
                // }
              }
            }
          })
        }else {
          this.$refs.vertifyCode.refreshCode();
          this.$message.error(res.errormsg||'登录失败');
        }
        this.hideLoading();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.canvass{
  position: absolute;
  overflow: hidden;
  z-index: -9999;
}
.login {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  background-image: url('~@/assets/image/1.png');
  background-size: 100% 100%;
}
.login-card {
  padding: 30px 50px;
  border-radius: 6px;
  //background-color: black;
  background: rgba(0,0,0,0.2);
  z-index: 1;
}
.login-header {
  display: flex;
  align-items: center;
  .login-logo {
    width: 44px;
    height: 45px;
    background-image: url('~@/assets/image/logo.png');
    background-size: 100% 100%;
  }
  .login-title {
    margin-left: 10px;
  }
  .welcome {
    line-height: 33px;
    color: rgba(255,255,255,0.85);
  }
  .system-name {
    font-size: 21px;
    line-height: 33px;
    color: rgba(255,255,255,0.85);
  }
  .system-desc {
    line-height: 22px;
    color: rgba(255,255,255,0.45);
  }
}
.login-form {
  margin-top: 30px;
  color: white;
  background-color:transparent
}
.compony-info {
  position: absolute;
  bottom: 20px;
  color: white;
  z-index: 1;
}
</style>